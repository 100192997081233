import React from 'react'
import ReportBody from '../../../components/ReportsDetails/ReportBody/ReportBody'
import ReportCover from '../../../components/ReportsDetails/ReportCover/ReportCover'
import ReportHeader from '../../../components/ReportsDetails/ReportHeader/ReportHeader'
import Footer from '../../../components/Footer'

import './reportdetails.scss'
import { graphql } from 'gatsby'

const ReportDetails = ({ data }) => {
  const { allContentfulReports: { nodes } } = data;
  const { imageUrl, shortDescription, title, } = nodes[0];

  // console.log(prFops.data);
  return (

    /* The User is redirected to this page based on an id or a slug to get an in-depth
        report. 
         */
    /* Report Details Information */
    <div className="report-detail-container">
      <div className="col">
        <ReportHeader />
      </div>
      <div className="col">
        <ReportCover />
      </div>
      <div className="col mt-3">
        <ReportBody imageUrl={imageUrl} shortDescription={shortDescription} />
      </div>
      <div className="mt-3">
        <Footer />
      </div>
    </div>
  )
}

export default ReportDetails


export const query = graphql`query GetReport($slug : String) {
  allContentfulReports(filter: {slug: {in: [$slug]}}) {
    nodes {
      contentful_id
      createdAt(formatString: "DD/MM/YYYY")
      imageUrl
      slug
      title
      shortDescription
    }
  }
}

`;
